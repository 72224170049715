/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import 'typeface-cormorant-garamond'
import "./src/styles/global.css"

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!("IntersectionObserver" in window)) {
    import("intersection-observer")
    console.log("# IntersectionObserver is polyfilled!")
  }
  if (window) {
    window.addEventListener("load", () => {
      document.body.className = document.body.className.replace(/\bno-js\b/, "")
    })
  }
}
