module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-131837603-1"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Agata Julia MUA","short_name":"AgataJuliaMUA","start_url":"/en/homepage/","background_color":"#FBE8D9","theme_color":"#64A2AD","display":"standalone","icon":"src/assets/icon-512x512.png","icon_options":{"purpose":"any maskable"},"lang":"en","localize":[{"start_url":"/es/inicio/","lang":"es","name":"Agata Julia MUA","short_name":"Agata Julia MUA"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5598b214a6be3c65fb13ba4058d29652"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
